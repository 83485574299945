import React  from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";
import { currencyFormatter, percentFormatter } from "../../../components/helpers/utils";

const columns = [
  { id: 'mask', label: 'Sklep', minWidth: 100 },
  { id: 'today', label: 'Przychody dziś', minWidth: 100, format: currencyFormatter.format },
  { id: 'todayShare', label: 'Udział %', minWidth: 100, format: percentFormatter.format },
  { id: 'month', label: 'Bieżący miesiąc', minWidth: 100, format: currencyFormatter.format },
  { id: 'monthShare', label: 'Udział %', minWidth: 100, format: percentFormatter.format },
];

function IncomePerMaskTable({ purchaseStore }) {

  const { incomePerMask, loadingIncomePerMask } = purchaseStore;

  return (
    <TableWithJson
      columns={columns}
      data={incomePerMask}
      loadingData={loadingIncomePerMask}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('purchaseStore')(observer(IncomePerMaskTable));

IncomePerMaskTable.propTypes = {
  purchaseStore: PropTypes.shape({
    incomePerMask: PropTypes.array,
    loadingIncomePerMask: PropTypes.bool
  })
};