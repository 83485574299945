import React, { useState }  from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";
// import { ImageButton } from './ImagePopup/ImageButton';
import { ImagePopup } from './ImagePopup/ImagePopup';

function TopCategoriesGlobalTable({ bannersStore }) {
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const { loadingTopBannersGlobal, topBannersGlobal } = bannersStore;

  const columns = [
    { id: 'position', label: 'Pozycja', minWidth: 100 },
    { id: 'analyticsId', label: 'ID', minWidth: 100, format: () => `baner_${Math.floor(Math.random() * 200)}` },
    { id: 'title', label: 'Nazwa', minWidth: 100, format: () => `Baner ${Math.floor(Math.random() * 200)}` },
    { id: 'store', label: 'Sklep', minWidth: 100, format: (value) => value !== '-' ? value.name.replace('four_fstore', 'sklep') : value },
    // { id: 'mainImage', label: 'Obrazek', minWidth: 100, format: (value) => value && value !== '-' ? ImageButton(setImageOpen, setImageUrl, value) : '(Brak obrazka)' },
    { id: 'todayValue', label: 'Dzisiaj', minWidth: 100 },
    { id: 'yesterdayValue', label: 'Wczoraj', minWidth: 100 },
    { id: 'weekValue', label: 'Ostatnie 7 dni', minWidth: 100 },
    { id: 'monthValue', label: 'Miesiąc', minWidth: 100 },
  ];

  return (
    <>
      <ImagePopup
        open={imageOpen}
        imageUrl={imageUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
        }}
      />
      <TableWithJson
        maxHeight={600}
        columns={columns}
        data={topBannersGlobal}
        loadingData={loadingTopBannersGlobal}
        withPagination={false}
        showJson={false}
      />
    </>
  );
}

export default inject('bannersStore')(observer(TopCategoriesGlobalTable));

TopCategoriesGlobalTable.propTypes = {
  bannersStore: PropTypes.shape({
    topBannersGlobal: PropTypes.array,
    loadingTopBannersGlobal: PropTypes.bool
  })
};