import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as stores from "./stores";
import { Provider } from "mobx-react";
import history from "./_app/history";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { plPL } from '@material-ui/core/locale';

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import Footer from "./components/Footer/Footer";
import NavBar from "./components/NavBar/NavBar";
import UsersPageContainer from "./views/UsersPage";
import LoginPageContainer from "./views/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import SalesPageContainer from "./views/SalesPage";
import DiscountsPageContainer from "./views/DiscountsPage";
import PersonPageContainer from "./views/PersonPage";
// import StoresPageContainer from "./views/StoresPage";
import WishlistsPageContainer from "./views/WishlistsPage";
// import CustomerJourneyPageContainer from "./views/CustomerJourneyPage";
import "./assets/css/index.css"
import RollbarError from "./components/RollbarError/RollbarError";
import ErrorPageContainer from "./views/ErrorPage";
import BannersPageContainer from "./views/BannersPage";
import ProductsPageContainer from "./views/ProductsPage";
const theme = createMuiTheme({},plPL);

ReactDOM.render(
  <RollbarError history={history} >
    <Provider {...stores}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <div style={{
            backgroundColor: "#e0e0e0",
            minHeight: "100vh",
            paddingBottom: "70px",
            display: "block",
            position: "relative"
          }}>
            <NavBar/>
            <Switch>
              <Route path="/login" component={LoginPageContainer}/>
              <PrivateRoute
                exact
                path="/"
                component={() => <Redirect to="/users"/>}
              />
              <PrivateRoute exact path="/users" component={UsersPageContainer}/>
              <PrivateRoute exact path="/sales" component={SalesPageContainer}/>
              <PrivateRoute exact path="/discounts" component={DiscountsPageContainer}/>
              <PrivateRoute exact path="/person" component={PersonPageContainer}/>
              {/*<PrivateRoute exact path="/stores" component={StoresPageContainer}/>*/}
              <PrivateRoute exact path="/wishlists" component={WishlistsPageContainer}/>
              {/*<PrivateRoute exact path="/customerjourney" component={CustomerJourneyPageContainer}/>*/}
              <PrivateRoute exact path="/banners" component={BannersPageContainer}/>
              <PrivateRoute exact path="/error" component={ErrorPageContainer}/>
              <PrivateRoute exact path="/products" component={ProductsPageContainer}/>
            </Switch>
            <Footer/>
          </div>
        </ThemeProvider>
      </Router>
    </Provider>
  </RollbarError>,
  document.getElementById("root")
);


