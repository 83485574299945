import React, { useState } from "react";
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { showLoaderOrPlaceHolder } from "../helpers/chartHelpers";
import { IconButton } from "@material-ui/core";
import JSONPretty from "react-json-pretty";
import InfoIcon from "@material-ui/icons/Info";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { InfoDialog } from "../InfoDialog/InfoDialog";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

const TableHeaderCell = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#0b123b'
  }
}))(TableCell);

export default function TableWithJson({ columns, data, loadingData, dataCount, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, withPagination = true, showJson = true, maxHeight = 440 }) {
  const useStyles = makeStyles({
    root: {
      width: '100%',
      backgroundColor: 'transparent'
    },
    container: {
      maxHeight: maxHeight,
    },
  });

  const classes = useStyles();
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const showInfoIcon = (content) =>
    (<IconButton onClick={() => {
      setDialogContent(content ? <JSONPretty data={content}/> : 'Brak danych');
      setShowInfoDialog(true);
    }}>
      <InfoIcon/>
    </IconButton>)

  return (<>
      <InfoDialog open={showInfoDialog} onClose={() => setShowInfoDialog(false)} content={dialogContent}/>
      <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableHeaderCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableHeaderCell>
              ))}
              {showJson && <TableHeaderCell
                  key="info_column_header"
                  style={{ minWidth: 100 }}
              >
                Json
              </TableHeaderCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ?
              <TableCell colSpan={columns.length + 1}>
                {showLoaderOrPlaceHolder(loadingData)}
              </TableCell>
              : data.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id !== 'object' ?
                            (column.format ? column.format(value) : value)
                            : showInfoIcon(value)
                          }
                        </TableCell>
                      );
                    })}
                    {showJson && <TableCell key="info_column">
                      <IconButton onClick={() => {
                        setDialogContent(<JSONPretty data={row}/>);
                        setShowInfoDialog(true);
                      }}>
                        <InfoIcon/>
                      </IconButton>
                    </TableCell>}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
        {withPagination && <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
    </Paper>
    </>
  );
}

TableWithJson.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loadingData: PropTypes.bool,
  dataCount: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  withPagination: PropTypes.bool,
  showJson: PropTypes.bool,
  maxHeight: PropTypes.number
};


