import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const StyledTextField = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: 'white',
      // color: '#808080',
    },
    '& label.Mui-focused': {
      // color: '#808080',
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#808080',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#808080',
        // borderColor: 'white'
      },
      '&:hover fieldset': {
        // borderColor: '#808080',
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        color: "white",
        // borderColor: '#808080',
        borderColor: 'white'
      },
    },
  },
})(TextField);