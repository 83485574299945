import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logos/logo.png';
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#0b123b",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    margin: "20px",
    color: "white",
  },
}));

const StyledLogo = styled.img`
  max-width: 150px;
  filter: invert(1);
`;

const NavBar = inject("authStore")(observer(({ authStore }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar style={{ background: '#0b123b' }} position="static">
        <Toolbar>
          <Typography variant="subtitle2" className={classes.title}>
            <Link to={"/"}><StyledLogo src={logo} alt="Goodylabs Analytics"/></Link>
          </Typography>
          {authStore.isAuthenticated &&
          <>
            {/*<Typography variant="subtitle2" className={classes.title}>*/}
            {/*  <Link style={{ color: "inherit" }} to={"/"}>Strona główna</Link>*/}
            {/*</Typography>*/}
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/users"}>Użytkownicy</Link>
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/sales"}>Sprzedaż</Link>
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/products"}>Produkty</Link>
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/discounts"}>Rabaty</Link>
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/person"}>Persona</Link>
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/banners"}>Banery</Link>
            </Typography>
            {/*<Typography variant="subtitle2" className={classes.title}>*/}
            {/*  <Link style={{ color: "inherit" }} to={"/stores"}>Sklepy Stacjonarne</Link>*/}
            {/*</Typography>*/}
            <Typography variant="subtitle2" className={classes.title}>
              <Link style={{ color: "inherit" }} to={"/wishlists"}>Ulubione produkty</Link>
            </Typography>
            {/*<Typography variant="subtitle2" className={classes.title}>*/}
            {/*  <Link style={{ color: "inherit" }} to={"/customerjourney"}>Customer journey</Link>*/}
            {/*</Typography>*/}
            <Typography variant="subtitle2" className={classes.title}>
              <Link onClick={authStore.logout} to={"/login"} style={{ color: "inherit" }}>Wyloguj</Link>
            </Typography>
          </>}
        </Toolbar>
      </AppBar>
    </div>
  );
}));

NavBar.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool
  })
};

export default NavBar;