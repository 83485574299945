import { action, observable } from "mobx";
import config from '../config'
import moment from "moment";
import 'moment/locale/pl'
import aggregationsStore from "./aggregationsStore";
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';

export class PurchaseStore {

  @observable purchaseEvents = [];
  @observable purchaseEventsCount = 0;
  @observable loadingPurchaseEventsData = true;

  @observable purchaseHourly = [];
  @observable loadingPurchaseHourlyData = true;

  @observable purchaseDaily = [];
  @observable loadingPurchaseDailyData = true;

  @observable purchaseLineChartData = [];
  @observable loadingPurchaseLineChartData = true;

  @observable incomePerMask = [];
  @observable loadingIncomePerMask = true;

  @observable purchasesPerMask = [];
  @observable loadingPurchasesPerMask = true;

  @observable avgTotalPricePerMask = [];
  @observable loadingAvgTotalPricePerMask = true;

  @observable thisWeekSum = 0;
  @observable lastWeekSum = 0;
  @observable monthSum = 0;

  @observable shortcuts = []
  @observable shortcutFrom = null;
  @observable shortcutTo = null;

  @observable hourlyDate = moment().toDate();

  @observable loadingAvailableMasksDateRange = true;
  @observable availableMasksDateRange = [];

  constructor() {
    this.generateShortcuts()
  }

  @action
  generateShortcuts = () => {
    const currentMonth = moment().month()
    const shortcuts = [];

    for(let i = 0; i <= currentMonth; i++) {
      const shortcut = {
        label: moment().startOf('year').add(i, 'month').format('MMMM'),
        from: moment().startOf('year').add(i, 'month').toDate(),
        to: moment().startOf('year').add(i, 'month').endOf('month').toDate()
      }
      shortcuts.push(shortcut)
    }
    this.shortcuts = shortcuts;
  }

  @action
  setHourlyDate = (date) => {
    this.hourlyDate = date;
  }

  @action
  setShortcuts = (label) => {
    const chosenShortcut = this.shortcuts.find(shortcut => shortcut.label === label);
    this.shortcutFrom = chosenShortcut.from;
    this.shortcutTo = chosenShortcut.to;
  }

  @action
  fetchPurchaseBarChartData = async (timeFrom, timeTo, step) => {
    this.loadingPurchaseBarChartData = true;
    const { data: { data } } = await aggregationsStore.fetchStatistics({ timeFrom, timeTo, step, statId: config.statIds.purchasesCount });
    this.purchaseBarChartData = data.map(stat => {
      const date = moment(stat.fromTs)
      stat.fromTs = date.locale('pl').format('lll').toString()
      return stat;
    });
    this.loadingPurchaseBarChartData = false;
  }

  @action fetchPurchaseEvents = async (query) => {
    this.purchaseEvents = [];
    this.loadingPurchaseEventsData = true;
    query.action = 'purchase'
    const { events, eventsCount } = await aggregationsStore.fetchEvents(query);
    this.purchaseEvents = events;
    this.purchaseEventsCount = eventsCount;
    this.loadingPurchaseEventsData = false;
  };

  @action
  fetchPurchaseDaily = async (fromDate, toDate, chosenMask) => {
    this.purchaseDaily = [];
    this.loadingPurchaseDailyData = true;

    const monthQuery = {
      statId: config.statIds.purchasesTotalPriceSum,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
    }

    const previousMonthQuery = {
      statId: config.statIds.purchasesTotalPriceSum,
      step: '1D',
      timeFrom: moment().startOf('month').subtract(1, 'month').toISOString(),
      timeTo: moment().subtract(1, 'month').toISOString(),
    }

    if(chosenMask && chosenMask !== 'Wszystkie') {
      monthQuery.statId = config.statIds.purchasesTotalPriceSumPerMask
      monthQuery.store = chosenMask;
      previousMonthQuery.statId = config.statIds.purchasesTotalPriceSumPerMask
      previousMonthQuery.store = chosenMask;
    }


    if(fromDate && toDate) {
      monthQuery.timeFrom = moment(fromDate).toString()
      monthQuery.timeTo = moment(toDate).toString()
      previousMonthQuery.timeFrom = moment(fromDate).subtract(1, 'month').toString()
      previousMonthQuery.timeTo = moment(toDate).subtract(1, 'month').toString()
    }

    let { data: month } = await aggregationsStore.fetchStatistics(monthQuery)
    let { data: previousMonth } = await aggregationsStore.fetchStatistics(previousMonthQuery)

    const shadow = {}
    previousMonth.forEach(stat => {
      const date = moment(stat.fromTs).add(1, 'month').toISOString();
      shadow[date] = stat.value
      return shadow
    })

    this.purchaseDaily = month.map((stat) => {
      stat.shadow = shadow[stat.fromTs] ? shadow[stat.fromTs] : 0
      const date = moment(stat.fromTs)
      stat.fromTs = date.locale('pl').format('ll').toString()
      return stat;
    });
    this.loadingPurchaseDailyData = false;
  }

  @action fetchPurchaseHourly = async (date) => {
    this.purchaseHourly = [];
    this.loadingPurchaseHourlyData = true;

    const todayQuery = {
      statId: config.statIds.purchasesTotalPriceSum,
      step: '1H',
      timeFrom: moment(date).startOf('day').toISOString() ,
      timeTo: moment(date).add(1, 'day').startOf('day').toISOString()
    }

    const yesterdayQuery = {
      statId: config.statIds.purchasesTotalPriceSum,
      step: '1H',
      timeFrom: moment(date).subtract(1, 'days').startOf('day').toISOString() ,
      timeTo: moment(date).startOf('day').toISOString()
    }

    const weekQuery = {
      statId: config.statIds.purchasesTotalPriceSum,
      step: '1H',
      timeFrom: moment(date).subtract(7, 'days').startOf('day').toISOString() ,
      timeTo: moment(date).subtract(6, 'days').startOf('day').toISOString()
    }

    let { data: today } = await aggregationsStore.fetchStatistics(todayQuery)
    let { data: yesterday } = await aggregationsStore.fetchStatistics(yesterdayQuery)
    let { data: week }  = await aggregationsStore.fetchStatistics(weekQuery)

    let todayIndex = 0;
    let yesterdayIndex = 0;
    let weekIndex = 0;
    const result = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourObject = { hour: hour }
      if (week[weekIndex] && hour === moment(week[weekIndex].fromTs).hour()) {
        hourObject.week = week[weekIndex].value ? parseFloat(week[weekIndex].value) : 0
        weekIndex += 1;

      } else {
        hourObject.week = 0;
      }
      if (today[todayIndex] && hour === moment(today[todayIndex].fromTs).hour()) {
        hourObject.today = today[todayIndex].value ? parseFloat(today[todayIndex].value) : 0
        todayIndex += 1;
      } else {
        hourObject.today = 0;
      }
      if (yesterday[yesterdayIndex] && hour === moment(yesterday[yesterdayIndex].fromTs).hour()) {
        hourObject.yesterday = yesterday[yesterdayIndex].value ? parseFloat(yesterday[yesterdayIndex].value) : 0
        yesterdayIndex += 1;
      } else {
        hourObject.yesterday = 0;
      }
      result.push(hourObject)
    }

    this.purchaseHourly = result;
    this.loadingPurchaseHourlyData = false;
  };

  @action fetchIncomePerMask = async () => {
    this.incomePerMask = [];
    this.loadingIncomePerMask = true;

    const todayQuery = {
      statId: config.statIds.purchasesTotalPriceSumPerMask,
      step: '1H',
      timeFrom: moment().startOf('day').toISOString() ,
      timeTo: moment().toISOString(),
      key: '$store'
    }

    const monthQuery = {
      statId: config.statIds.purchasesTotalPriceSumPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString() ,
      timeTo: moment().toISOString(),
      key: '$store'
    }

    let { data: { sum: day } } = await aggregationsStore.fetchStatisticsSum(todayQuery.timeFrom, todayQuery.timeTo, todayQuery.step, todayQuery.statId, todayQuery.key)
    const { data: { sum: month } } = await aggregationsStore.fetchStatisticsSum(monthQuery.timeFrom, monthQuery.timeTo, monthQuery.step, monthQuery.statId, monthQuery.key)

    const dayMasks = day.map(({ _id }) => _id)
    const monthMasks = month.map(({ _id }) => _id)
    const masks = [...new Set([...dayMasks, ...monthMasks])]

    const masksSorted = masks.sort();
    let dayAll = 0;
    let monthAll = 0;

    day.forEach(stat => dayAll += stat.sum)
    month.forEach(stat => monthAll += stat.sum)

    let dayIndex = 0;
    let monthIndex = 0;
    let monthSum = 0;
    const result = [];

    for(let i = 0; i < masksSorted.length; i++) {
      const row = { mask: masksSorted[i] }

      if (day[dayIndex] && masksSorted[i] === day[dayIndex]._id ) {
        row.today = day[dayIndex].sum ? day[dayIndex].sum : 0
        row.todayShare = dayAll > 0 ? (row.today / dayAll) : 0
        dayIndex += 1;
      } else {
        row.today = 0;
        row.todayShare = 0;
      }
      if (month[monthIndex] && masksSorted[i] === month[monthIndex]._id ) {
        row.month = month[monthIndex].sum ? month[monthIndex].sum : 0
        row.monthShare = monthAll > 0 ? (row.month / monthAll) : 0
        monthSum += row.month;
        monthIndex += 1;
      } else {
        row.month = 0;
        row.monthShare = 0;
      }
      //remove four_fstore from mask
      row.mask = `Sklep ${row.mask.split('_')[2]}`;
      result.push(row);
    }
    this.monthSum = monthSum
    this.incomePerMask = result;
    this.loadingIncomePerMask = false;
  };

  @action fetchPurchasesPerMask = async (fromDate, toDate) => {
    this.purchasesPerMask = [];
    this.loadingPurchasesPerMask = true;

    const monthQuery = {
      statId: config.statIds.purchasesCountPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString() ,
      timeTo: moment().toISOString(),
      key: '$store'
    }

    const previousMonthQuery = {
      timeFrom: moment().startOf('month').subtract(1, 'month').toISOString() ,
      timeTo: moment().subtract(1, 'month').toISOString(),
    }

    if(fromDate && toDate) {
      monthQuery.timeFrom = moment(fromDate).toString()
      monthQuery.timeTo = moment(toDate).toString()
      previousMonthQuery.timeFrom = moment(fromDate).subtract(1, 'month').toString()
      previousMonthQuery.timeTo = moment(toDate).subtract(1, 'month').toString()
    }

    let { data: { sum: month } } = await aggregationsStore.fetchStatisticsSum(monthQuery.timeFrom, monthQuery.timeTo, monthQuery.step, monthQuery.statId, monthQuery.key)
    let { data: { sum: previousMonth } } = await aggregationsStore.fetchStatisticsSum(previousMonthQuery.timeFrom, previousMonthQuery.timeTo, monthQuery.step, monthQuery.statId, monthQuery.key)

    const shadow = {}
    previousMonth.forEach(stat => {
      shadow[stat._id] = stat.sum
      return shadow
    })

    this.purchasesPerMask = month.map((stat) => {
      stat.shadow = shadow[stat._id] ? shadow[stat._id] : 0
      const date = moment(stat.fromTs)
      stat.fromTs = date.locale('pl').format('ll').toString()
      //remove four_fstore from mask
      stat._id = `Sklep ${stat._id.split('_')[2]}`;
      return stat;
    });
    this.loadingPurchasesPerMask = false;
  };

  @action fetchAvgTotalPricePerMask = async (fromDate, toDate) => {
    this.avgTotalPricePerMask = [];
    this.loadingAvgTotalPricePerMask = true;

    const monthQuery = {
      statId: config.statIds.purchasesAvgTotalPricePerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString() ,
      timeTo: moment().toISOString(),
      key: '$store'
    }

    const previousMonthQuery = {
      statId: config.statIds.purchasesAvgTotalPricePerMask,
      step: '1D',
      timeFrom: moment().startOf('month').subtract(1, 'month').toISOString() ,
      timeTo: moment().subtract(1, 'month').toISOString(),
      key: '$store'
    }

    if(fromDate && toDate) {
      monthQuery.timeFrom = moment(fromDate).toString()
      monthQuery.timeTo = moment(toDate).toString()
      previousMonthQuery.timeFrom = moment(fromDate).subtract(1, 'month').toString()
      previousMonthQuery.timeTo = moment(toDate).subtract(1, 'month').toString()
    }

    let { data: month } = await aggregationsStore.fetchStatisticsAvg(monthQuery)
    let { data: previousMonth } = await aggregationsStore.fetchStatisticsAvg(previousMonthQuery)

    const shadow = {}
    previousMonth.forEach(stat => {
      shadow[stat._id] = stat.value
      return shadow
    })

    this.avgTotalPricePerMask = month.map((stat) => {
      stat.shadow = shadow[stat._id] ? shadow[stat._id] : 0
      const date = moment(stat.fromTs)
      stat.fromTs = date.locale('pl').format('ll').toString()
      //remove four_fstore from mask
      stat._id = `Sklep ${stat._id.split('_')[2]}`;
      return stat;
    });
    this.loadingAvgTotalPricePerMask = false;
  };

  @action fetchTopBasicInfo = async (date) => {
    this.thisWeekSum = 0;
    this.lastWeekSum = 0;
    this.monthSum = 0;

    const stat = config.statIds.purchasesTotalPriceSum;

    const thisWeekTimeFrame = {
      timeFrom: moment(date).startOf('week').toISOString() ,
      timeTo: moment(date).add(1, 'day').startOf('day').toISOString(),
    }

    const lastWeekTimeFrame = {
      timeFrom: moment(date).startOf('week').subtract(7, 'days').toISOString() ,
      timeTo: moment(date).add(1, 'day').startOf('day').toISOString(),
    }

    const monthTimeFrame = {
      timeFrom: moment(date).startOf('month').toISOString(),
      timeTo: moment(date).add(1, 'day').startOf('day').toISOString(),
    }

    const { data: { sum: thisWeekSum } } = await aggregationsStore.fetchStatisticsSum(thisWeekTimeFrame.timeFrom, thisWeekTimeFrame.timeTo, '1H', stat, null);
    const { data: { sum: lastWeekSum } } = await aggregationsStore.fetchStatisticsSum(lastWeekTimeFrame.timeFrom, lastWeekTimeFrame.timeTo, '1D', stat, null);
    const { data: { sum: monthSum } } = await aggregationsStore.fetchStatisticsSum(monthTimeFrame.timeFrom, monthTimeFrame.timeTo, '1D', stat, null);

    this.thisWeekSum = thisWeekSum
    this.lastWeekSum = lastWeekSum
    this.monthSum = monthSum
  };

  @action
  fetchMasksForDateRange = async (fromDate, toDate) => {
    this.loadingAvailableMasksDateRange = true;
    this.availableMasksDateRange = [];

    const currentMonthQuery = {
      statId: config.statIds.purchasesTotalPriceSumPerMask,
      step: '1D',
      timeFrom: moment(fromDate).toISOString(),
      timeTo: moment(toDate).toISOString(),
      key: 'store'
    };

    const { data } = await API.get(`${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`);

    this.availableMasksDateRange = ['Wszystkie', ...data];
    this.loadingAvailableMasksDateRange = false;
  };

}


export default new PurchaseStore();
