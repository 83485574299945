import React, { useState, useEffect } from 'react';
import { Filters } from '../../../components/helpers/chartHelpers';
import moment from 'moment';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import CustomLineChart from '../../../components/CustomLineChart/CustomLineChart';
import CustomSelect from './CustomSelect';


const colors = ["#272727", "#868686"];
const keys = ['value'];
const keysTranslation = ['ilość transakcji'];
const xAxis = 'fromTs';

const PaymentLineChart = ({ personaStore }) => {
  const {
    shortcutFrom: from,
    shortcutTo: to,
    paymentsLineChartData,
    availableMasks,
    paymentTypes,
    paymentProviders,
    fetchPaymentsLineChartData,
    fetchMasks,
    fetchPaymentTypes,
    fetchPaymentProviders,
    loadingPaymentsLineChartData,
    loadingAvailableMasks,
    loadingPaymentTypes,
    loadingPaymentProviders,
    clearPaymentsLineChartData
  } = personaStore;

  const timeFrom = from ? moment(from).toDate() : moment().subtract(30, 'day').startOf('day').toDate();
  const timeTo = to ? moment(to).toDate() : moment().endOf('day').toDate();
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [mask, setMask] = useState('');

  useEffect(() => {
    if(from && to) {
      setStartDate(from)
      setEndDate(to)
    }
  }, [from, to])

  useEffect(() => {
    setPaymentType(null);
    setPaymentProvider(null);
    clearPaymentsLineChartData();
  }, [mask])

  useEffect(() => {
    fetchMasks(startDate, endDate);
  }, [startDate, endDate])

  useEffect(() => {
    if(mask) {
      fetchPaymentProviders(startDate, endDate, mask, paymentType);
    }
  }, [paymentType, mask])

  useEffect(() => {
    if(mask) {
      fetchPaymentTypes(startDate, endDate, mask, paymentProvider);
    }
  }, [paymentProvider, mask])

  useEffect(() => {
    if((paymentType || paymentProvider) && mask) {
      fetchPaymentsLineChartData(startDate, endDate, mask, paymentType, paymentProvider);
    } else {
      clearPaymentsLineChartData();
    }
  }, [startDate, endDate, paymentType, paymentProvider, mask])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly',  }}>
        <CustomSelect
          value={mask.replace('four_fstore', 'sklep')}
          options={availableMasks.map((item) => item.replace('four_fstore', 'sklep'))}
          loading={loadingAvailableMasks}
          setValue={(value) => setMask(value.replace('sklep', 'four_fstore'))}
          placeholder="Wybierz maskę..."
        />
        <CustomSelect
          value={paymentType}
          options={paymentTypes}
          loading={loadingPaymentTypes}
          setValue={setPaymentType}
          placeholder="Wybierz typ płatności..."
        />
        <CustomSelect
          value={paymentProvider}
          options={paymentProviders}
          loading={loadingPaymentProviders}
          setValue={setPaymentProvider}
          placeholder="Wybierz dostawcę płatności..."
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <div style={{ width: '100%' }}>
          <CustomLineChart
            loadingData={loadingPaymentsLineChartData}
            data={paymentsLineChartData}
            xAxisKey={xAxis}
            dataKeys={keys}
            keysTranslation={keysTranslation}
            keysColors={colors}
            withBrush={false}
            valueFormatter={(value, name, params) => [`${value} ( ${params.payload.percent} )`, name] }
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <div style={{ width: '100%' }}>
          <CustomLineChart
            loadingData={loadingPaymentsLineChartData}
            data={paymentsLineChartData}
            xAxisKey={xAxis}
            dataKeys={['percentRaw']}
            keysTranslation={['Udział']}
            keysColors={colors}
            withBrush={false}
            valueFormatter={(value, name) => [`${value}%`, name] }
            domain={[0, 100]}
          />
        </div>
      </div>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </Filters>
      </div>
    </div>
  );
};

export default inject('personaStore')(observer(PaymentLineChart));

PaymentLineChart.propTypes = {
  personaStore: PropTypes.shape({
    shortcutFrom: PropTypes.string,
    shortcutTo: PropTypes.string,
    paymentsLineChartData: PropTypes.array,
    availableMasks: PropTypes.array,
    paymentProviders: PropTypes.array,
    paymentTypes: PropTypes.array,
    fetchPaymentsLineChartData: PropTypes.func,
    fetchMasks: PropTypes.func,
    fetchPaymentProviders: PropTypes.func,
    fetchPaymentTypes: PropTypes.func,
    loadingPaymentsLineChartData: PropTypes.bool,
    loadingAvailableMasks: PropTypes.bool,
    loadingPaymentProviders: PropTypes.bool,
    loadingPaymentTypes: PropTypes.bool,
    clearPaymentsLineChartData: PropTypes.func
  })
}