import React, { useState } from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";
import { showLoaderOrPlaceHolder, Subtitle } from "../../../components/helpers/chartHelpers";
// import { ImageButton } from './ImagePopup/ImageButton';
import { ImagePopup } from './ImagePopup/ImagePopup';

function TopBannersPerMaskTable({ bannersStore }) {
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const { loadingTopBannersPerMaskCounter, topBannersPerMask, chosenMasks } = bannersStore;

  const columns = [
    { id: 'position', label: 'Pozycja', minWidth: 100 },
    { id: 'analyticsId', label: 'ID', minWidth: 100, format: () => `baner_${Math.floor(Math.random() * 200)}` },
    { id: 'title', label: 'Nazwa', minWidth: 100, format: () => `Baner ${Math.floor(Math.random() * 200)}` },
    { id: 'store', label: 'Sklep', minWidth: 100, format: (value) => value !== '-' ? value.name.replace('four_fstore', 'sklep') : value },
    // { id: 'mainImage', label: 'Obrazek', minWidth: 100, format: (value) => value && value !== '-' ? ImageButton(setImageOpen, setImageUrl, value) : '(Brak obrazka)' },
    { id: 'todayValue', label: 'Dzisiaj', minWidth: 100 },
    { id: 'yesterdayValue', label: 'Wczoraj', minWidth: 100 },
    { id: 'weekValue', label: 'Ostatnie 7 dni', minWidth: 100 },
    { id: 'monthValue', label: 'Miesiąc', minWidth: 100 },
  ];

  return (
    <div>
      <ImagePopup
        open={imageOpen}
        imageUrl={imageUrl}
        handleClose={() => {
          setImageOpen(false);
          setImageUrl('');
        }}
      />
      <div>
        {chosenMasks.map((mask, index) => (
          <div key={index}>
            <Subtitle>{mask.replace('four_fstore', 'sklep').toUpperCase()}</Subtitle>
              {(topBannersPerMask[mask] && topBannersPerMask[mask].length === 0) ?
                showLoaderOrPlaceHolder(loadingTopBannersPerMaskCounter !== 0, false)
                : (<TableWithJson
                  columns={columns}
                  data={topBannersPerMask[mask] || []}
                  loadingData={loadingTopBannersPerMaskCounter !== 0}
                  withPagination={false}
                  showJson={false}
                />)
              }
          </div>
        ))}
      </div>
    </div>
  )
}

export default inject('bannersStore')(observer(TopBannersPerMaskTable));

TopBannersPerMaskTable.propTypes = {
  bannersStore: PropTypes.shape({
    topBannersPerMask: PropTypes.array,
    loadingTopBannersPerMaskCounter: PropTypes.number,
    chosenMasks: PropTypes.array
  })
};