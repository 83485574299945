import React, { useEffect } from 'react';

import Select from 'react-select';
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";

function MaskSelect ({ bannersStore }) {
  const { loadingAvailableMasks, availableMasks, setChosenMasks, fetchBannersTop, chosenMasks } = bannersStore

  useEffect(() => {
    if(chosenMasks.length > 0) {
      let mask = null;
      for(mask of chosenMasks) {
        fetchBannersTop(mask);
      }
    }
  }, [chosenMasks])

  return (
    <>
      <Select
        isMulti
        isLoading={loadingAvailableMasks}
        placeholder="Wybierz maski..."
        getOptionLabel={(option) => option.replace('four_fstore', 'sklep')}
        getOptionValue={(option) => option}
        name="colors"
        options={availableMasks}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        onChange={(option) => {
          setChosenMasks(option)
        }}
      />
    </>
    )
}

export default inject('bannersStore')(observer(MaskSelect));

MaskSelect.propTypes = {
  bannersStore: PropTypes.shape({
    availableMasks: PropTypes.array,
    chosenMasks: PropTypes.array,
    loadingAvailableMasks: PropTypes.bool,
    setChosenMasks: PropTypes.func,
    fetchBannersTop: PropTypes.func,
  })
};