import { action, observable, computed } from "mobx";
import { firebaseAuth, session } from "../_app/firebase";
import history from "../_app/history";
import moment from "moment";

const TOKEN_KEY = "goody_analytics_api_token"
const EMAIL_KEY = "email"
const REFRESH_TOKEN_INTERVAL_MINUTES = 45

export class AuthStore {
  @observable user = null;
  @observable authError = "";
  @observable isLoggingOut = false;
  @observable isLoggingIn = false;
  @observable lastRefreshTokenTime = moment();

  constructor() {
    firebaseAuth.auth().onAuthStateChanged(user => {
      this.user = user;
      if (user === null) {
        window.localStorage.removeItem(TOKEN_KEY)
        window.localStorage.removeItem(EMAIL_KEY)
        history.push("/login")
      }
    })
  }

  @action.bound login = async (email, password) => {
    this.isLoggingIn = true
    firebaseAuth.auth().setPersistence(session).then(function () {
      return firebaseAuth.auth().signInWithEmailAndPassword(email, password)
    }).catch(error => this.authError = error.message);
    firebaseAuth.auth().onAuthStateChanged(user => {
      this.user = user
      if (this.user) {
        this.authError = "";
        window.localStorage.setItem(EMAIL_KEY, this.user.email)
        this.user.getIdToken(true).then(token => {
          this.lastRefreshTokenTime = moment();
          window.localStorage.setItem(TOKEN_KEY, token)
          this.isLoggingIn = false;
          history.push("/")
        })
      } else {
        this.isLoggingIn = false;
        this.user = null;
      }

    })
  }

  @action.bound
  async logout() {
    this.isLoggingOut = true;
    await firebaseAuth.auth().signOut()

    firebaseAuth.auth().onAuthStateChanged(async user => {
      if (user === null) {
        await window.localStorage.removeItem(TOKEN_KEY)
        await window.localStorage.removeItem(EMAIL_KEY)
        this.user = user
        this.isLoggingOut = false;
        history.push("/login")
      }
    })
  }

  @action.bound
  async refreshToken() {
    if (moment().diff(this.lastRefreshTokenTime, "minutes") >= REFRESH_TOKEN_INTERVAL_MINUTES) {
      let isRefreshed = false
      await this.user.getIdToken(true).then(function (idToken) {
        if (idToken) {
          isRefreshed = true
          localStorage.setItem(TOKEN_KEY, idToken)
        }
      }).catch(function (error) {
        console.log(error)
      });
      if (isRefreshed) {
        this.lastRefreshTokenTime = moment()
      }
    }
  }

  @computed get isAuthenticated() {
    return (this.user || window.localStorage.getItem(TOKEN_KEY)) && !this.isLoggingOut && !this.isLoggingIn
  }

  getToken = async () => {
    await this.refreshToken();
    return window.localStorage.getItem(TOKEN_KEY);
  }

}

export default new AuthStore();
