import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import moment from 'moment';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Loader from '../../../components/Loader/Loader';

export const DatePickerContainer = styled(Flex)`
  margin: 1px;
  justify-content: space-between;
`;

function ProductsMaskSelect ({ productsStore }) {
  const { loadingTopProductsPerMaskDropdown, availableMasksForProducts, setChosenProductsMasks, fetchTopProductsPerMask } = productsStore

  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    fetchTopProductsPerMask(startDate, endDate)
  }, [startDate, endDate])

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <Select
          isMulti
          isLoading={loadingTopProductsPerMaskDropdown}
          placeholder="Wybierz maski..."
          getOptionLabel={(option) => option.replace('four_fstore', 'sklep')}
          getOptionValue={(option) => option}
          name="colors"
          options={availableMasksForProducts}
          styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          onChange={(option) => {
            setChosenProductsMasks(option)
          }}
        />
      </div>
      <div style={{ width: '50%' }}>
        <DatePickerContainer style={{ justifyContent: 'space-between', margin: 1 }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </DatePickerContainer>
      </div>
      <div>
        {loadingTopProductsPerMaskDropdown && <Loader py={0} />}
      </div>
    </div>
    )
}

export default inject('productsStore')(observer(ProductsMaskSelect));

ProductsMaskSelect.propTypes = {
  productsStore: PropTypes.shape({
    availableMasksForProducts: PropTypes.array,
    loadingTopProductsPerMaskDropdown: PropTypes.bool,
    setChosenProductsMasks: PropTypes.func,
    fetchTopProductsPerMask: PropTypes.func,
  })
};